(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-modal/assets/javascripts/modal-header.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-modal/assets/javascripts/modal-header.js');
"use strict";

const ModalHeader = _ref => {
  let {
    children,
    className
  } = _ref;
  return React.createElement("header", {
    className: className
  }, React.createElement("h3", {
    className: "tipsen-modal-header"
  }, children));
};
setGlobal('svs.components.tipsen.tipsenModal.ModalHeader', ModalHeader);

 })(window);