(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-modal/assets/javascripts/sticky-bottom.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-modal/assets/javascripts/sticky-bottom.js');
"use strict";

const {
  ReactButton,
  ReactIcon
} = svs.ui;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const StickyBottomButton = _ref => {
  let {
    isBlock = false,
    isThin = false,
    isInverted = false,
    isDisabled = false,
    isTransparent = false,
    children,
    onClick
  } = _ref;
  return React.createElement(ReactButton, {
    block: isBlock,
    className: "sticky-bottom-nav",
    disabled: isDisabled,
    inverted: isInverted,
    onClick: onClick,
    thin: isThin,
    transparent: isTransparent
  }, children);
};
setGlobal('svs.components.tipsen.tipsenModal.StickyBottomButton', StickyBottomButton);
const StickyBottomLink = _ref2 => {
  let {
    isBlock = false,
    isThin = false,
    isInverted = true,
    isDisabled = false,
    isTransparent = false,
    children,
    to
  } = _ref2;
  return React.createElement(TipsenLink, {
    to: to
  }, React.createElement(StickyBottomButton, {
    isBlock: isBlock,
    isDisabled: isDisabled,
    isInverted: isInverted,
    isThin: isThin,
    isTransparent: isTransparent
  }, children));
};
setGlobal('svs.components.tipsen.tipsenModal.StickyBottomLink', StickyBottomLink);
const StickyButtonClose = _ref3 => {
  let {
    onClick,
    to,
    isInverted = true,
    isTransparent = false
  } = _ref3;
  const buttonText = 'Stäng';
  return to ? React.createElement(StickyBottomLink, {
    isBlock: true,
    isInverted: isInverted,
    isThin: true,
    isTransparent: isTransparent,
    to: to
  }, buttonText) : React.createElement(StickyBottomButton, {
    isBlock: true,
    isInverted: isInverted,
    isThin: true,
    isTransparent: isTransparent,
    onClick: onClick
  }, buttonText);
};
setGlobal('svs.components.tipsen.tipsenModal.StickyButtonClose', StickyButtonClose);
const StickyButtonIcon = _ref4 => {
  let {
    onClick,
    icon
  } = _ref4;
  return React.createElement(StickyBottomButton, {
    icon: icon,
    isThin: true,
    isTransparent: true,
    onClick: onClick
  }, React.createElement(ReactIcon, {
    icon: icon,
    size: "100"
  }));
};
setGlobal('svs.components.tipsen.tipsenModal.StickyButtonIcon', StickyButtonIcon);
const StickyBottom = _ref5 => {
  let {
    className,
    hasEvenplacement = true,
    children
  } = _ref5;
  const classNames = ['sticky-bottom'];
  if (className && className.length > 0) {
    classNames.push(className);
  }
  if (!hasEvenplacement) {
    classNames.push('sticky-bottom-not-evenly');
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, children);
};
setGlobal('svs.components.tipsen.tipsenModal.StickyBottom', StickyBottom);

 })(window);